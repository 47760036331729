@font-face {
  font-family: 'elering';
  src:  url('../assets/fonts/elering.eot?duodf2');
  src:  url('../assets/fonts/elering.eot?duodf2#iefix') format('embedded-opentype'),
    url('../assets/fonts/elering.ttf?duodf2') format('truetype'),
    url('../assets/fonts/elering.woff?duodf2') format('woff'),
    url('../assets/fonts/elering.svg?duodf2#elering') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'elering', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-request:before {
  content: "\e911";
  color: #006272;
}
.icon-electricity-consumption:before {
  content: "\e90c";
}
.icon-electricity-production:before {
  content: "\e90d";
}
.icon-gas-consumption:before {
  content: "\e90e";
}
.icon-arrow-down-solid:before {
  content: "\e90f";
}
.icon-arrow-up-solid:before {
  content: "\e910";
}
.icon-question:before {
  content: "\e908";
  color: #0469d4;
}
.icon-attention:before {
  content: "\e909";
  color: #da291c;
}
.icon-done:before {
  content: "\e90a";
  color: #009e45;
}
.icon-person:before {
  content: "\e90b";
  color: #006272;
}
.icon-api:before {
  content: "\e900";
  color: #006272;
}
.icon-application:before {
  content: "\e901";
  color: #006272;
}
.icon-consumption:before {
  content: "\e902";
  color: #006272;
}
.icon-data-trace:before {
  content: "\e903";
  color: #006272;
}
.icon-law:before {
  content: "\e904";
  color: #006272;
}
.icon-metering-points:before {
  content: "\e905";
  color: #006272;
}
.icon-production:before {
  content: "\e906";
  color: #006272;
}
.icon-renewable-energy:before {
  content: "\e907";
  color: #006272;
}
